export default function useSave(state) {
  const createHeaders = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", sessionStorage.getItem("token"));

    return myHeaders;
  };

  const requestOptions = (item) => {
    const raw = JSON.stringify(item);

    return {
      method: "POST",
      headers: createHeaders(),
      body: raw,
      redirect: "follow",
    };
  };

  const handleSave = (id, apiUrl, nestedId) => {
    const item = state.find((item) => item.id === Number(id));

    const url = nestedId
      ? `http://localhost:3001/${apiUrl}/` + nestedId
      : `http://localhost:3001/${apiUrl}/` + id;

    return fetch(url, requestOptions(item))
      .then((response) => response.json())
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  async function fetchGraphql(body) {
    const createHeaders = () => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "authorization",
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InNobXVseWl0ekBnbWFpbC5jb20iLCJpZCI6MSwiaWF0IjoxNjQ0ODczODY2LCJleHAiOjE2NDc0NjU4NjZ9.jXMn_EqLVcZ7mh0e_CBDrSAgnf5dQvvlJ5kv-rTHL5k"
      );
      return myHeaders;
    };

    return await fetch(process.env.API_URL || "http://localhost:3001/graphql", {
      method: "POST",
      headers: createHeaders(),
      body: JSON.stringify(body),
      redirect: "follow",
    })
      .then((result) => result.json())
      .then((result) => result);
  }

  return {
    handleSave,
    fetchGraphql,
  };
}
