import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import styled from "styled-components";
import Logo from "../components/header/SternsLogo.png";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  height: 150px;
  width: auto;
`;

const Container = styled.div`
  height: 250px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
`;

const Heading = styled.div``;

const InnerDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input``;

const Button = styled.button``;

function LogIn(props) {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const handleChangeUser = (e) => {
    setUserName(e.target.value);
  };

  const handleChangePass = (e) => {
    setPassword(e.target.value);
  };

  const history = useHistory();

  const setAuthInStorage = (token) => {
    window.sessionStorage.setItem("token", token);
  };

  async function signIn() {
    const response = await fetch(
      `${
        process.env.REACT_APP_API_SIGNIN_URL || "http://localhost:3006/signin"
      }`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: userName,
          password: password,
        }),
      }
    );

    const data = await response.json();

    return data;
  }

  async function fetchGraphql(body) {
    const createHeaders = () => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("authorization", sessionStorage.getItem("token"));
      return myHeaders;
    };

    return await fetch(
      process.env.REACT_APP_API_URL || "http://localhost:3006/graphql",
      {
        method: "POST",
        headers: createHeaders(),
        body: JSON.stringify(body),
        redirect: "follow",
      }
    )
      .then((result) => result.json())
      .then((result) => {
        if (result.errors) {
          return result;
        } else {
          return result;
        }
      });
  }

  async function getUserPermissions() {
    return await fetchGraphql({
      query: `query Query {
        userPermissions
      }`,
    }).then((result) => result.data.userPermissions);
  }

  async function handleLogin() {
    signIn().then((data) => {
      if (data.userId) {
        setAuthInStorage(data.token);

        // we fetch users permissions and push history acordingly
        getUserPermissions().then((data) => {
          props.setStateP(data);

          const isMixer = data.includes("isMixer");
          const isLocker = data.includes("isLocker");
          const isMachine = data.includes("isMachine");

          if (isMixer) {
            history.push("/mixers-new");
          } else if (isLocker) {
            history.push("/lockers");
          } else if (isMachine) {
            history.push("/machines");
          } else {
            history.push("/recipes");
          }
        });
      } else if (
        data === "To many failed log in attempts please conntact system admin"
      ) {
        window.alert(
          "To many failed log in attempts please conntact system admin"
        );
      } else {
        alert(
          "Your Username or Password does not match please enter a valid Username and Password"
        );
      }
    });
  }

  const login = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <Main>
      <Img src={Logo} />

      <Container>
        <InnerDiv>
          <Heading>User Name</Heading>
          <Input
            type="text"
            onKeyUp={(e) => login(e)}
            onChange={(e) => handleChangeUser(e)}
          />
        </InnerDiv>

        <InnerDiv>
          <Heading>Password</Heading>
          <Input
            type="password"
            onKeyUp={(e) => login(e)}
            onChange={(e) => handleChangePass(e)}
          />
        </InnerDiv>

        <Button onClick={() => handleLogin()}>Log In</Button>
      </Container>
    </Main>
  );
}

export default withRouter(LogIn);
