import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Collapse, Checkbox } from "antd";
import ClickBox from "./ClickBox";
import TaskSideBar from "./TaskSideBar";
import useSave from "../.././pages/Machines/useSave";

import "./list.css";

const { Panel } = Collapse;

const Container = styled.div`
  display: flex;
  margin: 40px;
  height: 100%;
`;

const Task = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;

  &:last-child {
    margin-bottom: 0px;
  }
`;

function TaskList() {
  const [activeTask, setActiveTask] = useState();
  const [active, setActive] = useState(false);
  const [tasks, setTasks] = useState([]);

  const { fetchGraphql } = useSave("");

  useEffect(() => {
    const query = {
      query: `query ScheduledTaskSteps {
          scheduledTasks(taskStatus: "false") {
            taskId
            scheduledTaskId
            name
            description
            dueDate
            status
          }
        }`,
    };

    fetchGraphql(query).then((result) => {
      if (result.data.scheduledTasks[0] === null) {
        setTasks([]);
      } else {
        setTasks(result.data.scheduledTasks);
      }
    });
  }, []);

  const s = new Date().setHours(0, 0, 0, 0);
  const s2 = tasks[1]
    ? +new Date(tasks[1].dueDate).setHours(24, 0, 0, 0)
    : "(0";

  // console.log(s, "hi", s2);

  return (
    <Container>
      <Collapse defaultActiveKey={["1"]} className="w-100">
        <Panel className="bg-dark-red" header="Overdue" key="1">
          {tasks
            .filter(
              (item) =>
                new Date(item.dueDate).setHours(24, 0, 0, 0) <
                new Date().setHours(0, 0, 0, 0)
            )
            .map((item, index) => (
              <Task
                key={item.scheduledTaskId}
                onClick={() => {
                  setActiveTask(item.scheduledTaskId);
                  setActive(true);
                }}
              >
                <ClickBox status={item.status} />

                <div className="gray fw1 ml5">
                  {new Date(item.dueDate).toLocaleDateString("en-US")}
                </div>
                <div className="b ml5">{item.description}</div>
              </Task>
            ))}
        </Panel>
        <Panel className="bg-dark-blue" header="Today" key="2">
          {tasks
            .filter(
              (item) =>
                +new Date(item.dueDate).setHours(24, 0, 0, 0) ===
                +new Date().setHours(0, 0, 0, 0)
            )
            .map((item) => (
              <Task
                key={item.scheduledTaskId}
                onClick={() => {
                  setActiveTask(item.scheduledTaskId);
                  setActive(true);
                }}
              >
                <Checkbox status={item.status} />
                <div className="gray fw1 ml5">
                  {new Date(item.dueDate).toLocaleDateString("en-US")}
                </div>
                <div className="b ml5">{item.description}</div>
              </Task>
            ))}
        </Panel>
        <Panel className="bg-dark-gray" header="Later" key="3">
          {tasks
            .filter(
              (item) =>
                new Date(item.dueDate).setHours(24, 0, 0, 0) >
                new Date().setHours(0, 0, 0, 0)
            )
            .map((item) => (
              <Task
                key={item.scheduledTaskId}
                onClick={() => {
                  setActiveTask(item.scheduledTaskId);
                  setActive(true);
                }}
              >
                <ClickBox status={item.status} />
                <div className="gray fw1 ml5">
                  {new Date(item.dueDate).toLocaleDateString("en-US")}
                </div>
                <div className="b ml5">{item.description}</div>
              </Task>
            ))}
        </Panel>
      </Collapse>
      {active ? <TaskSideBar active={active} activeTask={activeTask} /> : null}
    </Container>
  );
}

export default TaskList;
