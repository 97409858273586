import React from "react";
import { Link, withRouter } from "react-router-dom";

const LogIn = () => {
  const logout = () => {
    var myHeaders = new Headers();
    myHeaders.append("authorization", sessionStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      token: sessionStorage.getItem("token"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`https://api.formulationworld.com/signout`, requestOptions)
      .then((response) => response.text())
      .then((result) => {})
      .catch((error) => {});
    sessionStorage.removeItem("token");
  }
  return (
    <Link onClick={logout} className="white dim ml-auto mr2" to="/">
      Log Out
    </Link>
  );
};
export default withRouter(LogIn);
