import styled from "styled-components";
import React, { useState } from "react";
import { Collapse, Switch, Checkbox } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

export default function ClickBox(props) {
  const [clicked, setClicked] = useState();

  return (
    <Switch
      checked={props.status === "false" ? false : true}
      className={props.status === "false" ? "bg-red" : "bg-blue"} 
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
    />
  );
}


