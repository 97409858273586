import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { Checkbox } from "antd";
import ClickBox from "./ClickBox";
import useSave from "../.././pages/Machines/useSave";

const TaskContainer = styled.div`
  margin-left: 40px;
  width: 800px;
  height: 100%;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
`;

export default function TaskSideBar(props) {
  const [task, setTask] = useState([]);
  const [steps, setSteps] = useState([]);
  // console.log(task)

  const { fetchGraphql } = useSave("");

  const updateStep = (checked, list) => {
    // console.log(list, task);

    const done = {
      query: `mutation UpdateStepStatus {
        createStepStatus(taskStepId:"${list.id}" , scheduledTaskId: "${task[0].scheduledTaskId}", taskStepStatus:"done"  )
      }`,
    };

    const notDone = {
      query: `mutation UpdateStepStatus {
      deleteStepStatus(taskStepId:"${list.id} ", scheduledTaskId: "${task[0].scheduledTaskId}" )
    }`,
    };

    const copyState = [...steps];

    const step = copyState.find((item) => item.id === list.id);

    if (checked) {
      fetchGraphql(done);
      step.status = "done";
    } else {
      fetchGraphql(notDone);
      step.status = null;
    }

    setSteps(() => copyState);

    const allDone = steps.every((item) => item.status === "done");

    const copyTaskState = [...task];

    if (allDone) {
      copyTaskState[0].status = "done";
    } else {
      copyTaskState[0].status = "false";
    }

    setTask(copyTaskState)

    // console.log("all done", allDone);
  };

  useEffect(() => {
    const query = {
      query: `query ScheduledTaskSteps {
          scheduledTask(scheduledTaskId: "${props.activeTask}") {
            taskId
            scheduledTaskId
            name
            description
            dueDate
            status
          }
          scheduledTaskSteps(scheduledTaskId: "${props.activeTask}") {
            id
            step
            status
          }
        }`,
    };

    fetchGraphql(query).then((result) => {
      // console.log(result);
      setTask([]);
      setTask(result.data.scheduledTask);
      setSteps(result.data.scheduledTaskSteps);
    });
  }, [props.activeTask]);

  return props.active ? (
    <TaskContainer>
      <div className="ma4 fw1">
        {task.map((item) => (
          <div>
            <div className="flex">
              <ClickBox status={item.status} />

              <div className="ml3 fw1 gray">
                {new Date(item.dueDate).toLocaleDateString("en-US")}
              </div>
              <div className="ml3 b">{item.description}</div>
            </div>
            <div className="mt3 bt pt3">
              {steps
                .sort((a, b) => a.id - b.id)
                .map((list) => (
                  <div className="ml2">
                    <Checkbox
                      checked={list.status === "done" ? true : false}
                      onChange={(e) => updateStep(e.target.checked, list)}
                    >
                      {list.step}
                    </Checkbox>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </TaskContainer>
  ) : null;
}
