import React from "react";
import styled from "styled-components";
import Header from "./components/header/Header";
import LogIn from "./pages/logIn";
import initialData from "./data";
import {
  BrowserRouter as Router,
  Prompt,
  Switch,
  Route,
} from "react-router-dom";
import Tasks from "./pages/Tasks";
import { Spin, Space } from "antd";
const Machines = React.lazy(() => import("./pages/Machines/Machine"));
const RecipePage = React.lazy(() => import("./pages/recipes/Recipes"));
const IngredientPage = React.lazy(() => import("./pages/Ingredients"));
const DashboardPage = React.lazy(() =>
  import("./pages/dashboard/DashboardPage")
);
const MixersPage = React.lazy(() => import("./pages/Mixers"));
const Mixers = React.lazy(() => import("./pages/MixersNew"));
const EmployeeLockers = React.lazy(() => import("./pages/EmployeeLockers"));

// const SubMenu = React.lazy(() => import("./components/submenu/SubMenu"));

export const Permission = React.createContext("undefined");

const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100vh;
`;

const Block = styled.div`
  display: flex;
  flex-grow: 50;
  flex-flow: row;
  overflow: hidden;
  /* height: calc(100vh - 76.938px); */
`;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialData;
  }

  //General
  setIngredientsState = (ingredients) => {
    this.setState({ ingredients: ingredients });
  };

  setRecipeState = (recipes) => {
    this.setState({ recipes: recipes });
  };

  setInitialRecipeState = (recipes) => {
    this.setState({ initialRecipes: recipes });
  };

  setUsersState = (users) => {
    this.setState({ users: users });
  };

  setRolesState = (roles) => {
    this.setState({ roles: roles });
  };

  setPermissionsState = (permissions) => {
    this.setState({ permissions: permissions });
  };

  setUsersPermissionsState = (usersPermissions) => {
    this.setState({ usersPermissions: usersPermissions });
  };

  setShouldConfirm = (boolen) => {
    this.setState({ shouldConfirm: boolen });
  };

  render() {
    const { ingredients, recipes, usersPermissions } = this.state;

    try {
      return (
        <Permission.Provider value={usersPermissions}>
          <Router
            getUserConfirmation={(message, callback) => {
              // this is the default behavior
              const allowTransition = window.confirm(message);
              if (allowTransition) {
                this.setShouldConfirm(false);
              }
              callback(allowTransition);
            }}
          >
            {this.state.shouldConfirm ? (
              <Prompt message="Are you sure you want to leave with out saving?" />
            ) : null}
            <React.Suspense
              fallback={
                <Space size="middle" align="baseline" className="h-100 w-100">
                  <Spin size="large" />
                </Space>
              }
            >
              <Switch>
                <Route exact path="/">
                  <LogIn
                    setIngredientsState={this.setIngredientsState}
                    setRecipeState={this.setRecipeState}
                    setUsersState={this.setUsersState}
                    setRolesState={this.setRolesState}
                    setPermissionsState={this.setPermissionsState}
                    setInitialRecipeState={this.setInitialRecipeState}
                    setStateP={this.setUsersPermissionsState}
                  />
                </Route>
                <Route path="/ingredients">
                  <IngredientPage
                    setIngredientsState={this.setIngredientsState}
                    setRecipeState={this.setRecipeState}
                    setUsersState={this.setUsersState}
                    setRolesState={this.setRolesState}
                    setPermissionsState={this.setPermissionsState}
                    setStateP={this.setUsersPermissionsState}
                    ingredients={ingredients}
                    recipes={recipes}
                  />
                </Route>
                <Route path="/recipes">
                  <RecipePage
                    setShouldConfirm={this.setShouldConfirm}
                    setIngredientsState={this.setIngredientsState}
                    setRecipeState={this.setRecipeState}
                    setUsersState={this.setUsersState}
                    setRolesState={this.setRolesState}
                    setPermissionsState={this.setPermissionsState}
                    setStateP={this.setUsersPermissionsState}
                    ingredients={ingredients}
                    recipes={recipes}
                    usersPermissions={usersPermissions}
                    list={ingredients}
                  />
                </Route>
                <Route path="/products">
                  <Container>
                    <Header />
                    <Block></Block>
                  </Container>
                </Route>
                <Route path="/reports">
                  <Container>
                    <Header />
                    <Block></Block>
                  </Container>
                </Route>
                <Route path="/mixers">
                  <MixersPage
                    setStateP={this.setUsersPermissionsState}
                    handleClone={this.handleClone}
                    handleSaveRecipe={this.handleSaveRecipe}
                    ingredients={ingredients}
                    recipes={recipes}
                    setRecipeState={this.setRecipeState}
                    setInitialRecipeState={this.setInitialRecipeState}
                  />
                </Route>
                <Route path="/mixers-new">
                  <Mixers
                    setStateP={this.setUsersPermissionsState}
                    handleClone={this.handleClone}
                    handleSaveRecipe={this.handleSaveRecipe}
                    ingredients={ingredients}
                    recipes={recipes}
                    setRecipeState={this.setRecipeState}
                    setInitialRecipeState={this.setInitialRecipeState}
                  />
                </Route>
                <Route path="/dashboard">
                  <DashboardPage
                    setIngredientsState={this.setIngredientsState}
                    setRecipeState={this.setRecipeState}
                    setUsersState={this.setUsersState}
                    setRolesState={this.setRolesState}
                    setPermissionsState={this.setPermissionsState}
                    setStateP={this.setUsersPermissionsState}
                    ingredients={this.state.ingredients}
                    users={this.state.users}
                    roles={this.state.roles}
                    permissions={this.state.permissions}
                    usersPermissions={usersPermissions}
                  />
                </Route>
                <Route path="/lockers">
                  <EmployeeLockers
                    setIngredientsState={this.setIngredientsState}
                    setRecipeState={this.setRecipeState}
                    setUsersState={this.setUsersState}
                    setRolesState={this.setRolesState}
                    setPermissionsState={this.setPermissionsState}
                    setStateP={this.setUsersPermissionsState}
                  />
                </Route>
                <Route path="/machines">
                  <Machines
                    setIngredientsState={this.setIngredientsState}
                    setRecipeState={this.setRecipeState}
                    setUsersState={this.setUsersState}
                    setRolesState={this.setRolesState}
                    setPermissionsState={this.setPermissionsState}
                    setStateP={this.setUsersPermissionsState}
                  />
                </Route>
                <Route path="/tasks">
                  <Tasks
                    setIngredientsState={this.setIngredientsState}
                    setRecipeState={this.setRecipeState}
                    setUsersState={this.setUsersState}
                    setRolesState={this.setRolesState}
                    setPermissionsState={this.setPermissionsState}
                    setStateP={this.setUsersPermissionsState}
                  />
                </Route>
              </Switch>
            </React.Suspense>
          </Router>
        </Permission.Provider>
      );
    } catch (errer) {
      // console.log(errer)
      return "error"
    }
  }
}

export default App;
