import React from "react";
import Menu from "./Menu";
import LogIn from "./LogIn";
import Logo from "./Logo";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

const Nav = styled.nav`
  background-color: black;
  padding: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 55;
`;

const Header = (props) => {
  return (
    <Nav>
      <Logo />
      <Menu />
      <LogIn />
    </Nav>
  );
};
export default withRouter(Header);
