import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Permission } from "../../app";

// const usersPermissions = Permission;

function Menu() {
  const usersPermissions = useContext(Permission);
  const viewDashboard = usersPermissions.includes("viewDashboard");
  const viewMachines = usersPermissions.includes("viewMachines");
  const viewTasks = usersPermissions.includes("viewTasks");
  const viewIngredients = usersPermissions.includes("viewIngredients");
  const viewRecipes = usersPermissions.includes("viewRecipes");

  return (
    <ul className="list flex white ma0 pa0 pl3">
      {viewDashboard ? (
        <Link className="pr3 white dim" to="/dashboard">
          Dashboard
        </Link>
      ) : null}
      {viewIngredients ? (
        <Link className="pr3 white dim" to="/ingredients">
          Ingredients
        </Link>
      ) : null}
      {viewRecipes ? (
        <Link className="pr3 white dim" to="/recipes">
          Recipes
        </Link>
      ) : null}
      {viewMachines ? (
        <Link className="pr3 white dim" to="/machines">
          Machines
        </Link>
      ) : null}
      {viewTasks ? (
        <Link className="pr3 white dim" to="/tasks">
          Tasks
        </Link>
      ) : null}
    </ul>
  );
}

export default Menu;
