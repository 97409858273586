import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../components/header/Header";
import TaskList from "../components/TaskList/TaskList";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`;

function Tasks(props) {
  useEffect(() => {
    async function getUserPermissions() {
      const response = await fetch(
        `https://api.formulationworld.com/permissions/1`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            authorization: sessionStorage.getItem("token"),
          },
        }
      );

      const data = await response.json();

      return data;
    }

    getUserPermissions().then((data) => props.setStateP(data));
  }, []);

  return (
    <Container>
      <Header />
      <TaskList />
    </Container>
  );
}

export default Tasks;
