export const machineData = [];

const data = {
  ingredients: [
    {
      id: 1,
      key: 0,
      name: "Sugar", // ingredient name
      price: 2.36, // number
      isDeleted: null,
      cost: [
        {
          cost: 2.32,
          date: "20/01/2021",
        },
      ],
      units: [
        {
          unitId: 1,
          unit: "LB", // Qt || Cup -- free form text
          key: 0,
          type: "ingredient", // ingredient || note
          pounds: 2, // number
          draggableId: "0",
        },
      ],
    },
  ],

  recipes: [
    {
      id: 1,
      name: "Black and whites", // recipe name
      price: 2.36, // number
      totalPounds: 0, //number
      cost: [],
      ingredients: [
        {
          ingredientId: 1,
          ingredientUnitId: 1,
          key: 0,
          ingredient: "",
          quintity: 0, // quintity of ingredient
          unit: "", // Qt || Cup -- free form text
          pounds: 0,
          percent: 0,
          cost: 0.0,
          type: "ingredient", // ingredient || note
          draggableId: "0",
          note: "I am a note",
        },
      ],
    },
  ],

  initialRecipes: [],

  products: [
    {
      id: 1,
      name: "",
      description: "",
      cost: 203.25,
      price: 5.99,
      yield: 25,
      catagorys: [
        {
          catagoryId: 1,
          catagoryName: "",
          pictureURL: "",
        },
      ],
      variations: [
        {
          variationId: 1,
          variationName: "itemSize",
          varientId: 1,
          varientName: "small",
          code: "",
          pictureUrl: "",
        },
        {
          variationId: 1,
          variationName: "itemSize",
          varientId: 1,
          varientName: "medium",
          code: "",
          pictureUrl: "",
        },
        {
          variationId: 2,
          variationName: "container",
          varientId: 1,
          varientName: "xd3",
          code: "",
          pictureUrl: "",
        },
      ],
      workflows: [
        {
          workflowId: 1,
          variationId: 1,
          conponents: [
            {
              componentType: "recipe",
              conponentId: 1,
            },
          ],
        },
      ],
    },
  ],

  users: [
    {
      id: 1,
      isDeleted: false,
      firstName: "Shmuly", // recipe name
      lastName: "Itzkowitz", // recipe name
      phonNumber: "917 580 1458",
      email: "shmulyitz@gmail.com",
      roles: [
        {
          roleId: 1,
          key: 0,
          roleName: "Super User",
        },
      ],
    },
  ],

  roles: [
    {
      id: 1,
      roleName: "Super Admin",
      roleDescription: "",
      permissions: [
        {
          permissionId: 1,
          permissionName: "",
        },
      ],
    },
  ],

  permissions: [
    {
      id: 1,
      permissionName: "dashboard",
      permissionDescription: "",
    },
  ],

  usersPermissions: [],

  shouldConfirm: false,
};

export default data;
